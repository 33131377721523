import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import chaletApi from '../../../../api/chaletApi';
import { useAppDispatch, useAppSelector } from 'redux/hook';
import { CategoriesTable } from 'ui/components/partials/Admin/CategoriesTable';
import { AddBox, Sync } from '@mui/icons-material';
import toast from 'react-hot-toast';
import { AddCategoryModal } from 'ui/components/partials/Admin/AddCategoryModal';
import { openSyncModal } from 'redux/modalSlice';
import { SyncModal } from 'ui/components/partials/Admin/SyncModal';

export const AdminMenu = () => {
  const { palette } = useTheme();

  const [isFetching, setIsFetching] = useState(true);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const { user, selectedBranch } = useAppSelector((state) => state.user);

  const [getCompleteMenu, completeMenu] = chaletApi.useLazyGetCompleteMenuQuery();
  const [createCopyMenu, result] = chaletApi.useCreateCopyMenuMutation();
  const dispatch = useAppDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    if (selectedBranch) {
      setIsFetching(true);

      const branch = selectedBranch.id;
      getCompleteMenu(branch).finally(() => {
        setIsFetching(false);
      });
    }
  }, [selectedBranch]);

  useEffect(() => {
    if (result.isError) {
      toast.error('Ha ocurrido un error creando el menú');
    }
  }, [result]);

  const createMenu = () => {
    if (user) {
      const branchId = user.branchWork.branchId;
      const shopId = user.branchWork.shopId;
      createCopyMenu({ branchId, shopId });
    }
  };

  if (isFetching || completeMenu.isLoading) {
    return (
      <Box sx={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }} padding={4}>
        <Box>
          <CircularProgress size={200} color="primary" />
        </Box>
      </Box>
    );
  }

  if (completeMenu.data && completeMenu.data.branchMenus.length > 0) {
    const data = completeMenu.data;
    const menus = data.branchMenus;
    const currentMenu = menus[currentTab];

    return (
      <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', margin: 2 }}>
          {data.needsSync === true && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'end'
              }}>
              <Button
                startIcon={<Sync />}
                variant="contained"
                onClick={() => dispatch(openSyncModal())}>
                Sincronizar
              </Button>
            </Box>
          )}

          <Tabs value={currentTab} onChange={handleChange}>
            {menus.map((menu) => {
              return <Tab key={menu.name} label={menu.name} />;
            })}
          </Tabs>
        </Box>
        <CategoriesTable data={currentMenu.categories} />
        {currentMenu && (
          <AddCategoryModal
            selectedMenu={currentMenu}
            correlative={currentMenu.categories.length + 1}
          />
        )}
        <SyncModal
          missingCategories={data.missingCategories}
          missingProducts={data.missingProducts}
          currentMenu={currentMenu}
        />
      </>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
      <Card
        elevation={0}
        sx={{
          m: 2,
          width: 350,
          height: 80,
          border: '1px solid #ef3d32',
          backgroundColor: '#F1DEDC',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'left',
          flexDirection: 'column',
          position: 'relative',
          ':hover': {
            boxShadow: '0 0 0 0px #fff, 0 0 0 2px #ef3d32',
            cursor: 'pointer'
          }
        }}
        onClick={createMenu}>
        <Stack direction={'row'} alignItems={'center'} marginLeft={3} spacing={2}>
          <AddBox
            sx={{
              width: 50,
              height: 50,
              color: palette.primary.main
            }}
          />
          <Typography
            sx={{
              color: palette.primary.main,
              fontWeight: 500,
              textAlign: 'center',
              fontSize: '24px'
            }}>
            Crear Menú
          </Typography>
        </Stack>
      </Card>
    </Container>
  );
};
