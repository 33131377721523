import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { OrderDetail } from 'types/Order';

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4nY1M2xLER.ttf'
    },
    {
      src: 'https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1y4nY1M2xLER.ttf',
      fontWeight: 'semibold'
    },
    {
      src: 'https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf',
      fontWeight: 'bold'
    }
  ]
});

const styles = StyleSheet.create({
  tableRow: {
    flexDirection: 'row',
    paddingTop: 4,
    paddingBottom: 4
  },
  tableColHeader: {
    width: '25%',
    borderStyle: 'solid',
    borderBottomColor: '#EF3D32', // Primary color for header underline
    borderBottomWidth: 2,
    alignItems: 'center',
    padding: 4
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderBottomColor: '#EF3D32', // Primary color for column underline
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: 'center',
    padding: 4
  },
  tableCell: {
    margin: 'auto',
    fontSize: 10,
    fontFamily: 'Open Sans',
    fontWeight: 'bold'
  },
  tableCellHeader: {
    margin: 'auto',
    fontSize: 10,
    fontFamily: 'Open Sans',
    fontWeight: 'bold'
  },
  totalContainer: {
    marginTop: 24,
    textAlign: 'right',
    paddingRight: 8
  },
  totalText: {
    fontSize: 12,
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    color: '#EF3D32' // Primary color for the total text
  },
  subDetailRow: {
    flexDirection: 'row',
    backgroundColor: '#fac4c1' // Secondary color for subdetail row background
  },
  subDetailContainer: {
    paddingTop: 2,
    paddingBottom: 2
  },
  subDetailCol: {
    width: '25%',
    borderStyle: 'solid',
    borderColor: '#EF3D32', // Primary color for subdetail borders
    borderWidth: 0,
    borderTopWidth: 1,
    padding: 2
  },
  subDetailCell: {
    fontSize: 8,
    fontFamily: 'Open Sans',
    textAlign: 'left',
    color: '#000',
    paddingLeft: 8
  }
  // You can add more styles as needed
});

type InvoiceSubdetailProps = {
  detail: OrderDetail;
};

const InvoiceSubdetail = ({ detail }: InvoiceSubdetailProps) => {
  const { name, quantity, price, total } = detail;
  return (
    <View style={styles.subDetailRow}>
      <View style={styles.subDetailCol}>
        <Text style={styles.subDetailCell}>{quantity}</Text>
      </View>
      <View style={styles.subDetailCol}>
        <Text style={styles.subDetailCell}>{name}</Text>
      </View>
      <View style={styles.subDetailCol}>
        <Text style={styles.subDetailCell}>{price}</Text>
      </View>
      <View style={styles.subDetailCol}>
        <Text style={styles.subDetailCell}>{total}</Text>
      </View>
    </View>
  );
};

export default InvoiceSubdetail;
