import React, { useEffect, useState } from 'react';
import chaletApi from 'api/chaletApi';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { InvoiceCard } from './InvoiceCard';

type InvoiceProps = {
  billingDetailId: number;
};

const Invoices: React.FC<InvoiceProps> = ({ billingDetailId }) => {
  const [isFetching, setIsFetching] = useState(true);

  const [getInvoicesByBillingDetail, { data, isLoading, isError }] =
    chaletApi.useLazyGetInvoicesByBillingDetailIdQuery();

  useEffect(() => {
    if (billingDetailId) {
      setIsFetching(true);

      getInvoicesByBillingDetail(billingDetailId).finally(() => {
        setIsFetching(false);
      });
    }
  }, [billingDetailId]);

  if (isFetching || isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '50vh'
        }}>
        <CircularProgress size={80} color="primary" />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box sx={{ textAlign: 'center', padding: 4 }}>
        <Typography color="error" variant="h6">
          Something went wrong
        </Typography>
      </Box>
    );
  }

  if (!data || data.data.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', padding: 4 }}>
        <Typography variant="h6">Aún no se han generado facturas para este comercio</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2, maxWidth: '1200px', margin: '0 auto' }}>
      <Grid container spacing={2}>
        {data.data.map((invoice) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={invoice.id}>
            <InvoiceCard invoice={invoice} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Invoices;
