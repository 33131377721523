import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import 'yup-phone-lite';
import { Box, Button, CircularProgress, TextField, Typography, Stack, Alert } from '@mui/material';
import chaletApi from 'api/chaletApi';
import { useAppSelector } from 'redux/hook';

type FormCreateBillingDetailType = {
  name: string;
  nit: string;
  dui: string;
  nrc: string;
  address: string;
  phone: string;
  email: string;
};

const schema = yup.object().shape({
  name: yup.string().required(),
  nit: yup.string().required(),
  dui: yup.string().required(),
  nrc: yup.string().required(),
  address: yup.string().required(),
  phone: yup
    .string()
    .phone('SV', 'Ingrese un número de teléfono válido')
    .max(8, 'Máximo 8 dígitos')
    .required('El número de teléfono es obligatorio'),
  email: yup
    .string()
    .email('Correo electrónico no válido')
    .required('El correo electrónico es obligatorio')
});

const CreateBillingDetail: React.FC = () => {
  const { selectedBranch } = useAppSelector((state) => state.user);

  const [createBillingDetails, { isLoading }] = chaletApi.useCreateBillingDetailMutation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<FormCreateBillingDetailType>({
    defaultValues: {
      name: '',
      nit: '',
      dui: '',
      nrc: '',
      address: '',
      phone: '',
      email: ''
    },
    resolver: yupResolver(schema)
  });

  const onSubmit = (billingDetail: FormCreateBillingDetailType) => {
    createBillingDetails({
      branchId: selectedBranch?.id,
      name: billingDetail.name,
      nit: billingDetail.nit,
      dui: billingDetail.dui,
      nrc: billingDetail.nrc,
      address: billingDetail.address,
      phone: billingDetail.phone,
      email: billingDetail.email
    })
      .unwrap()
      .then((payload) => {
        if (payload.success) {
          toast.success('Información de facturación creada con éxito');
        } else {
          toast.error(payload.error);
        }
      })
      .catch((error) => {
        toast.error('Error al crear la información de facturación');
      });
  };

  return (
    <Box
      sx={{
        mx: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '600px',
        padding: '16px'
      }}>
      <Typography variant="h4" sx={{ mb: 2, fontWeight: 'bold' }}>
        Crear Información de Facturación
      </Typography>
      <Typography variant="body1" sx={{ mb: 4, textAlign: 'center', color: '#555' }}>
        Completa los siguientes campos para registrar la información de facturación necesaria.
      </Typography>
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="warning">
          Una vez creada la información de facturación, no podrás modificarla, asegúrate que la
          información que ingreses está correcta
        </Alert>
      </Stack>
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ width: '100%' }}>
        <TextField
          margin="normal"
          required
          fullWidth
          label="Nombre o razón social"
          autoFocus
          {...register('name')}
          error={!!errors.name}
          helperText={errors.name?.message}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="Número de Identificación Tributaria (NIT)"
          {...register('nit')}
          error={!!errors.nit}
          helperText={errors.nit?.message}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="Documento Único de Identidad (DUI)"
          {...register('dui')}
          error={!!errors.dui}
          helperText={errors.dui?.message}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="Número de Registro de Contribuyente (NRC)"
          {...register('nrc')}
          error={!!errors.nrc}
          helperText={errors.nrc?.message}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="Dirección fiscal"
          {...register('address')}
          error={!!errors.address}
          helperText={errors.address?.message}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="Teléfono"
          {...register('phone')}
          error={!!errors.phone}
          helperText={errors.phone?.message}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          label="Correo electrónico"
          {...register('email')}
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 3
          }}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => reset()}
            sx={{
              fontSize: '1rem',
              padding: '5px 10px'
            }}>
            Limpiar
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isLoading}
            sx={{
              fontSize: '1rem',
              padding: '5px 10px',
              backgroundColor: 'primary.main'
            }}>
            Siguiente {isLoading && <CircularProgress size={20} color="inherit" />}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateBillingDetail;
