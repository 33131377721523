import React, { useState, useEffect } from 'react';
import { daysOfTheWeek } from 'utils/date';
import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Grid,
  Stack,
  Alert
} from '@mui/material';
import { Branch, Schedule } from '../../../../../types/Shop';
import chaletApi from 'api/chaletApi';
import dayjs, { Dayjs } from 'dayjs';
import EditIcon from '@mui/icons-material/Edit';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers';
import { useAppSelector } from 'redux/hook';
import { formatTimeSchedule } from 'utils/formatter';

type ScheduleTableProps = {
  branch: Branch;
};

type UpdateScheduleModalProps = {
  isOpen: boolean;
  onClose: () => void;
  schedule: Schedule;
};

const UpdateScheduleModal: React.FC<UpdateScheduleModalProps> = ({ isOpen, onClose, schedule }) => {
  const [start, setStart] = useState<Dayjs | null>(null);
  const [end, setEnd] = useState<Dayjs | null>(null);
  const [editShopSchedule, result] = chaletApi.useEditBranchScheduleMutation();

  const { user } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (schedule) {
      const open = dayjs(schedule.startTime, 'HH:mm');
      const close = dayjs(schedule.endTime, 'HH:mm');

      setStart(open);
      setEnd(close);
    }
  }, [schedule]);

  const updateSchedule = () => {
    if (!start || !end) return;

    const adjustedStartTime = dayjs(start).set('year', 1970).set('month', 0).set('date', 1);
    const startTime = adjustedStartTime.format('HH:mm:ss');

    const adjustedEndTime = dayjs(end).set('year', 1970).set('month', 0).set('date', 1);
    const endTime = adjustedEndTime.format('HH:mm:ss');

    const data: any = {
      branchId: user?.branchWork.branch.id,
      days: [
        {
          id: schedule.id,
          day: schedule.day,
          startTime,
          endTime
        }
      ]
    };

    editShopSchedule(data);
  };

  useEffect(() => {
    if (result.isSuccess) {
      onClose();
      setStart(null);
      setEnd(null);
    }
  }, [result.isSuccess]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          alignItems: 'center',
          maxWidth: '90%',
          minWidth: 320
        }}>
        <Typography variant="h6" component="h2" sx={{ mb: 2, fontWeight: 'bold' }}>
          Actualizar Horario
        </Typography>
        <Stack sx={{ width: "100%" }} spacing={2}>
          <Alert severity="info">
            Selecciona AM o PM para la hora de apertura y cierre de tu tienda.
          </Alert>
        </Stack>
        <Grid container spacing={2} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <Typography color="text.secondary" align="center" sx={{ mb: 1 }}>
              Abre
            </Typography>
            <TimePicker
              value={start}
              onChange={(newValue) => setStart(newValue)}
              sx={{ width: '100%' }}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock
              }}
              ampm={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography color="text.secondary" align="center" sx={{ mb: 1 }}>
              Cierra
            </Typography>
            <TimePicker
              value={end}
              onChange={(newValue) => setEnd(newValue)}
              sx={{ width: '100%' }}
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock
              }}
              ampm={true}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 2 }}>
          <Button variant="outlined" onClick={onClose} sx={{ width: 120 }}>
            Cerrar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={updateSchedule}
            sx={{ width: 120, fontWeight: 'bold' }}>
            Actualizar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const ScheduleTable: React.FC<ScheduleTableProps> = ({ branch }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDay, setCurrentDay] = useState<any>();
  const [editShopSchedule] = chaletApi.useEditBranchScheduleMutation();

  const { user } = useAppSelector((state) => state.user);

  const handleOpenModal = (day: any) => {
    const dayOfWeek = sch.find((item) => item.day === day);
    setCurrentDay(dayOfWeek);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const updateIsClosedCheckbox = (schedule: any) => {
    const data: any = {
      branchId: user?.branchWork.branch.id,
      days: [
        {
          day: schedule.day,
          closed: !schedule.closed
        }
      ]
    };
    editShopSchedule(data);
  };

  const sch = branch.schedule.map(({ id, day, startTime, endTime, closed }) => {
    const startFormat = formatTimeSchedule(startTime);
    const endFormat = formatTimeSchedule(endTime);
    return {
      id,
      day,
      startTime: startFormat,
      endTime: endFormat,
      closed: closed
    };
  });

  const SortedSchedule = sch.sort((a, b) => {
    return a.day - b.day;
  });

  return (
    <TableContainer
      sx={{
        mt: 2,
        width: { xs: '100vw', md: '600px' },
        maxWidth: '100%',
        boxShadow: 3,
        borderRadius: 2
      }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow sx={{ backgroundColor: 'primary.main' }}>
            <TableCell
              align="center"
              sx={{
                color: '#fff',
                fontWeight: 'bold'
              }}>
              Día
            </TableCell>
            <TableCell align="center" sx={{ color: '#fff', fontWeight: 'bold' }}>
              Abre
            </TableCell>
            <TableCell align="center" sx={{ color: '#fff', fontWeight: 'bold' }}>
              Cierra
            </TableCell>
            <TableCell align="center" sx={{ color: '#fff', fontWeight: 'bold' }}>
              Cerrado
            </TableCell>
            <TableCell align="center" sx={{ color: '#fff', fontWeight: 'bold' }}>
              Actualizar
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {SortedSchedule.map((schedule) => (
            <TableRow
              key={schedule.day}
              sx={{ '&:nth-of-type(odd)': { backgroundColor: 'grey.100' } }}>
              <TableCell align="center" sx={{ fontSize: '0.9rem', padding: 1 }}>
                {daysOfTheWeek[schedule.day]}
              </TableCell>
              <TableCell align="center" sx={{ fontSize: '0.9rem', padding: 1 }}>
                {schedule.startTime}
              </TableCell>
              <TableCell align="center" sx={{ fontSize: '0.9rem', padding: 1 }}>
                {schedule.endTime}
              </TableCell>
              <TableCell align="center" sx={{ padding: 1 }}>
                <Checkbox
                  checked={schedule.closed}
                  onClick={() => updateIsClosedCheckbox(schedule)}
                  sx={{ color: 'primary.main' }}
                />
              </TableCell>
              <TableCell align="center" sx={{ padding: 1 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleOpenModal(schedule.day)}
                  sx={{ minWidth: '60px', padding: '4px 8px' }}>
                  <EditIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {sch && currentDay && (
        <UpdateScheduleModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          schedule={currentDay}
        />
      )}
    </TableContainer>
  );
};
