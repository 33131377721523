import { Card, CardContent, Divider, Typography } from '@mui/material';
import React from 'react';
import { Invoices } from 'types/Invoices';
import { formatDate } from 'utils/formatter';
import { BranchSummaryPDFDownloadLink } from '../InvoiceDocument/BranchSummaryPDFDownloadLink';

export type InvoiceCardProps = {
  invoice: Invoices;
};

const InvoiceCard: React.FC<InvoiceCardProps> = ({ invoice }) => {
  return (
    <Card
      key={invoice.id}
      sx={{
        maxWidth: 600,
        boxShadow: 4,
        borderRadius: 3,
        border: '1px solid #e0e0e0',
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
          transform: 'scale(1.02)'
        }
      }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Factura #{invoice.invoiceNumber}
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body2" color="textSecondary">
          <strong>Fecha de emisión:</strong> {formatDate(invoice.issueDate)}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <strong>Desde:</strong> {formatDate(invoice.fromDateOrders)}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <strong>Hasta:</strong> {formatDate(invoice.toDateOrders)}
        </Typography>
      </CardContent>
      <BranchSummaryPDFDownloadLink invoice={invoice} />
    </Card>
  );
};

export default InvoiceCard;
