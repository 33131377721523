import React, { useEffect, useState } from 'react';
import chaletApi from 'api/chaletApi';
import { useAppSelector } from 'redux/hook';
import CreateBillingDetail from './CreateBillingDetail/CreateBillingDetail';
import { BillingDetailCard } from './BillingDetailCard';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Invoices } from './Invoices';

const Invoicing: React.FC = () => {
  const { selectedBranch } = useAppSelector((state) => state.user);
  const [isFetching, setIsFetching] = useState(true);

  const [getBillingDetail, { data, isLoading, isError }] =
    chaletApi.useLazyGetBillingDetailByBranchQuery();

  useEffect(() => {
    if (selectedBranch) {
      setIsFetching(true);

      getBillingDetail({ branchId: selectedBranch.id }).finally(() => {
        setIsFetching(false);
      });
    }
  }, [selectedBranch]);

  if (isFetching || isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '50vh'
        }}>
        <CircularProgress size={80} color="primary" />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box sx={{ textAlign: 'center', padding: 4 }}>
        <Typography color="error" variant="h6">
          Something went wrong
        </Typography>
      </Box>
    );
  }

  if (data) {
    return (
      <Box sx={{ padding: 2, maxWidth: '1200px', margin: '0 auto' }}>
        <Box sx={{ marginBottom: 4 }}>
          <BillingDetailCard billingDetail={data} />
        </Box>
        <Box>
          <Invoices billingDetailId={data.id} />
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 2, maxWidth: '800px', margin: '0 auto' }}>
      <CreateBillingDetail />
    </Box>
  );
};

export default Invoicing;
