import React from 'react';
import { Paper, Typography, Grid, Box } from '@mui/material';
import { AccountCircle, Business, Email, Phone, LocationOn } from '@mui/icons-material';
import { BillingDetails } from 'types/BillingDetails';

type BillingDetailCardProps = {
  billingDetail: BillingDetails;
};

const BillingDetailCard: React.FC<BillingDetailCardProps> = ({ billingDetail }) => {
  return (
    <Box
      sx={{
        my: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 2
      }}>
      <Paper
        elevation={4}
        sx={{
          maxWidth: 600,
          width: '100%',
          padding: 3,
          borderRadius: 4,
          backgroundColor: '#fff'
        }}>
        <Box
          sx={{
            textAlign: 'center',
            paddingBottom: 2,
            borderBottom: '2px solid #e0e0e0',
            marginBottom: 3
          }}>
          <Typography variant="h4" color="primary" gutterBottom>
            Información de Facturación
          </Typography>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <AccountCircle color="primary" sx={{ marginRight: 1 }} />
            <Typography variant="body1">
              <strong>Nombre o razón social:</strong> {billingDetail.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <Business color="primary" sx={{ marginRight: 1 }} />
            <Typography variant="body1">
              <strong>Número de Identificación Tributaria (NIT):</strong> {billingDetail.nit}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <Business color="primary" sx={{ marginRight: 1 }} />
            <Typography variant="body1">
              <strong>Documento Único de Identidad (DUI):</strong> {billingDetail.dui}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <Business color="primary" sx={{ marginRight: 1 }} />
            <Typography variant="body1">
              <strong>Número de Registro de Contribuyente (NRC):</strong> {billingDetail.nrc}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <LocationOn color="primary" sx={{ marginRight: 1 }} />
            <Typography variant="body1">
              <strong>Dirección fiscal:</strong> {billingDetail.address}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <Phone color="primary" sx={{ marginRight: 1 }} />
            <Typography variant="body1">
              <strong>Número de Teléfono:</strong> {billingDetail.phone}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <Email color="primary" sx={{ marginRight: 1 }} />
            <Typography variant="body1">
              <strong>Correo Electrónico:</strong> {billingDetail.email}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default BillingDetailCard;
